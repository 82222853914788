module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","mobile":"(max-width: 990px)","md":"(max-width: 1024px)","l":"(max-width: 1536px)","xl":"(max-width: 1999px)","xxl":"(min-width: 2000px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pt","en"],"defaultLanguage":"pt","generateDefaultLanguagePage":false,"siteUrl":"https://invisual.pt/","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.index":"/en","urls.about":"about-us","urls.contacts":"contacts","urls.posts":"posts","urls.work":"communication-agency","urls.design":"design","urls.3d":"3d-projects","urls.web":"web","urls.mkt":"digital-marketing","urls.photography":"photography","urls.video":"video","urls.advisory":"advisory","urls.events":"events","urls.terms":"terms-and-conditions","urls.cookies":"cookie-policy","urls.privacy":"privacy-policy","urls.portfolio":"portfolio"},"pt":{"urls.index":"/","urls.about":"sobre-nos","urls.contacts":"contactos","urls.posts":"posts","urls.work":"agencia-de-comunicacao","urls.design":"design","urls.3d":"projetos-3d","urls.web":"web","urls.mkt":"marketing-digital","urls.photography":"fotografia","urls.video":"video","urls.advisory":"assessoria","urls.events":"eventos","urls.terms":"termos-e-condicoes","urls.cookies":"politica-de-cookies","urls.privacy":"politica-de-privacidade","urls.portfolio":"portfolio"}},"defaultLocale":"pt","languages":["pt","en"],"prefix":"urls."},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://news.invisual.pt/wp/graphql","perPage":50,"schema":{"perPage":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":15,"previewRequestConcurrency":5},"type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","video/mov"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
